@import './lib/margin.scss';
@import './lib/breakpoints.scss';
@import './lib/font.scss';
@import './lib/display.scss';
@import './lib/buttons.scss';
@import './lib/size.scss';
@import './lib/animatable.scss';
@import './lib/padding.scss';
@import './lib/font-size-color.scss';

h1 {
  margin: 0px;
}

p {
  margin: 0px;
}

a {
  text-decoration: none;
}

span {
  display: flex;
}

input {
  border: none;
}

input:focus {
  outline: none;
}

button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

button:focus {
  outline: none;
}

.outline {
  &-none {
    outline: none;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Vietnam', 'Helvetica', sans-serif;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.vietnam-regular {
  font-family: 'vietnam', 'Helvetica', sans-serif;
  font-weight: 400;
}

.vietnam-medium {
  font-family: 'vietnam', 'Helvetica', sans-serif;
  font-weight: 500;
}

.vietnam-semibold {
  font-family: 'Lato', 'Helvetica', sans-serif;
  font-weight: 600;
}

.vietnam-bold {
  font-family: 'Lato', 'Helvetica', sans-serif;
  font-weight: 700;
}


.main-font {
  font-family: 'Vietnam'; // just replace the font with your desired choice

  &-thin {
    @extend .main-font;
    font-weight: 100;
  }

  &-light {
    @extend .main-font;
    font-weight: 300;
  }

  &-regular {
    @extend .main-font;
    font-weight: 400;
  }

  &-medium {
    @extend .main-font;
    font-weight: 500;
  }

  &-semi-bold {
    @extend .main-font;
    font-weight: 600;
  }

  &-bold {
    @extend .main-font;
    font-weight: 700;
  }

  &-semi-black {
    @extend .main-font;
    font-weight: 800;
  }

  &-black {
    @extend .main-font;
    font-weight: 900;
  }
}

.font-color {
  &-black {
    color: black;
  }

  &-grey {
    color: #6f6f6f;
  }
 
  &-light-grey {
    color: #9B9B9B;
  }
  &-tan {
    color: #cebd87;
  }

  &-blue {
    color: #508cd0;
  }

  &-navy-blue {
    color: #193e5d;
  }

  &-sky-blue {
    color: #7da5c2;
  }

  &-green {
    color:#26CA90
  }

  &-purple {
    color: #686886;
  }

  &-pink {
    color: #b30419;
  }

  &-red {
    color: #f30c0c;
  }
  
  &-white {
    color: white;
  }

  &-dark-grey {
    color: #4A4A4A;
  }
}

.background-color {
  &-white {
    background: white;
  }

  &-sky-blue{
    background: #7DA5C2;
  }
  &-grey {
    background: #6f6f6f;
  }
  &-light-grey {
    background: #f3f3f3;
  }
  &-faded {
    &-blue {
      background: #b2d5ff;
    }

    &-tan {
      background: #ffebaa;
    }

    &-green {
      background: #cef1b2;
    }

    &-purple {
      background: #afafd2;
    }

    &-pink {
      background: #fba1ac;
    }
    &-transparent {
      background: transparent;
    }
    &-red {
      background-color: #f30c0c;
    }

    &-faint-grey {
      background: rgba(248, 248, 248, 1);
    }
  }
}


.cursor {
  &-pointer {
    cursor: pointer;
  }
  &-disable {
    cursor: not-allowed;
  }
}

.ant-form-horizontal .ant-form-item-control {
  flex: auto !important;
}