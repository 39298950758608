.font {
  &-red {
    color: #c00000;
  }
  &-grey {
    color: #666666;
  }
  &-light-grey {
    color: #cccccc;
  }
  &-light-green {
    color: #26ca90;
  }
  &-blue {
    color: #00314b;
  }
  &-black {
    color: #000000;
  }
  &-red-danger {
    color: red;
  }
  &-white {
    color: white;
  }
  &-family {
    font-family: 'vietnam', 'Helvetica', sans-serif;
  }
  &-family-Be {
    font-family: 'Be Vietnam';
  }
  &-underline {
    text-decoration: underline;
  }
}

.background-color {
  &-red {
    background-color: #c00000;
  }
  &-grey {
    background-color: #666666;
  }
  &-light-grey {
    background-color: #cccccc;
  }
  &-light-green {
    background-color: #26ca90;
  }
  &-blue {
    background-color: #00314b;
  }
  &-black {
    background-color: #000000;
  }
  &-white {
    background-color: white;
  }
}

.background {
  &-red {
    background: #c00000;
  }
  &-grey {
    background: #666666;
  }
  &-light-grey {
    background: #cccccc;
  }
  &-light-green {
    background: #26ca90;
  }
  &-blue {
    background: #00314b;
  }
  &-black {
    background: #000000;
  }
  &-white {
    background: white;
  }
}

.heading {
  &-h1 {
    font-size: 40px;
    font-weight: 800;
    font-family: 'vietnam', 'Helvetica', sans-serif;
  }
  &-h2 {
    font-size: 30px;
    font-weight: bolder;
    font-family: 'vietnam', 'Helvetica', sans-serif;
  }
  &-h3 {
    font-size: 25px;
    font-weight: 800;
    font-family: 'vietnam', 'Helvetica', sans-serif;
  }
  &-h4 {
    font-size: 16px;
    font-weight: bold;
    font-family: 'vietnam', 'Helvetica', sans-serif;
  }
}

.body {
  &-large {
    font-size: 18px;
    font-weight: 600;
    font-family: 'vietnam', 'Helvetica', sans-serif;
  }
  &-normal {
    font-size: 15px;
    font-weight: 500;
    font-family: 'vietnam', 'Helvetica', sans-serif;
  }
}

.label {
  &-large {
    font-size: 14px;
    font-weight: 500;
    font-family: 'vietnam', 'Helvetica', sans-serif;
  }
  &-normal {
    font-size: 12px;
    font-weight: 800;
    font-family: 'vietnam', 'Helvetica', sans-serif;
  }
  &-bold {
    font-size: 13px;
    font-weight: 800;
    font-family: 'vietnam', 'Helvetica', sans-serif;
  }
  &-semi-bold {
    font-size: 14px;
    font-weight: 600;
    font-family: 'vietnam', 'Helvetica', sans-serif;
  }
}
