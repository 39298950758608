
@import './variables.scss';

.login-btn {

    background-color: $primary !important;
    border-color: $primary;
    color: white !important;
}

.login-text {
    color: white;
    white-space: nowrap;
}

.login-bg {
    background-color: $primary !important;
}

.password-icon {
    position: absolute;
    top:45px;
    right: 10px;
    color: $primary;
}

.forgot-div {
    position:relative ;
    top:40px;
    left:20px;
}
.forgot-back {
    background-color: $primary;
    padding: 15px;
    border-radius: 50%;
}


.invalid-credentials {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}