.primary { 
    color: $primary 
}

.ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 10px !important;
    font-size: 15px !important;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
}

.image-box {
    padding: 8px;
    border: 1px solid lightgray;
    height: 105px;
    width: 100px;
    cursor: pointer
}

.image-active {
    @extend .image-box;
    border: 3px solid #00CA95 !important;
}

.no-padding-top {
    padding-top: 0px !important;
}