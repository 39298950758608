.zoning-text {
    white-space: nowrap;
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-text {
    @extend .zoning-text;
    width: 95px;
}